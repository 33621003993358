if (module.hot) {
  module.hot.accept();
}

$( "#subscribeForm" ).submit(function( event ) {
  const email = $('#email').val();
  const name = $('#name').val();
  const phone = $('#phone').val();
  window.location.href = "mailto:support@megargs.com?subject=" + "Message from globalpay.cash" + "&body=" + name + ' ' + email + ' ' + phone;
  event.preventDefault();
});

$('#tabs').on('click', 'li', function (){
  $('li').removeClass('active');
  $('ul').toggleClass('expanded');
  $(this).addClass('active');
  var tab_id = $(this).attr('data-tab');
  $('.tab-content').removeClass('current');
  $(this).addClass('current');
  $('#'+tab_id).addClass('current');
});
